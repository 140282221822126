// i18next-extract-mark-ns-start markets-motorcycle-electrification

import { MARKETS_CAPABILITIES_ELECTRIC_NAV, MOTORCYCLE_NAV, LINKS, HYBRID_ELECTRIFICATION_PRODUCTS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketMotorcyclePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const capability = getImage(data.capability.childImageSharp.gatsbyImageData);
	const ignite = getImage(data.ignite.childImageSharp.gatsbyImageData);
	const wave = getImage(data.wave.childImageSharp.gatsbyImageData);
	const mech = getImage(data.mech.childImageSharp.gatsbyImageData);
	const vectis = getImage(data.vectis.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>Electric and hybrid electric motorcycle design</h2>
						<p>
							The road to a net zero future includes different electrification technologies for different applications.
							For many motorcycles from scooters to racing bikes, this is a hybrid combination of electric and Internal
							Combustion Engine (ICE) using traditional and non-carbon e-fuels.
							Offering a balance between range, convenience, emissions reduction and cost - Hybrid Electric Vehicle (HEV),
							Plug-in Hybrid Electric Vehicle (PHEV) hybrids hold a place in the market for a range of two-wheel machines.
						</p>
						<p>
							This leaves Original Equipment Manufacturers (OEMs) and manufacturers with the challenge of simultaneously
							developing smaller IC engines for hybrid systems, compatible battery / motor units and investing in non-carbon fuels,
							all with tighter resources and budgets.
						</p>
					</Trans>

					{capability && (
						<p>
							<GatsbyImage image={capability} alt="Hybrid motorcycle system model" />
						</p>
					)}

					<Trans i18nKey="content_2">
						<p>
							In the early stages of development physical prototype modelling is expensive, requiring virtual simulation tools to answer
							many of the 'What ifs' before committing to a design path.
							Complete vehicle system simulation models are vital in the selection of the correct balance of components to
							meet performance, emissions, cost and drivability requirements for the both the full electric and the
							ICE / electric hybrid controlling predicting their behaviour under different duty cycles.
						</p>
						<p>
							Electric and hybrid electric system analysis consists of four packages that calculate combustion, temperatures, mechanical losses, and emissions data used in the system model.
						</p>
					</Trans>

					{ignite && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={ignite}
								alt="IGNITE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_3">
						<p>
							IGNITE is a complete physics-based hybrid simulation package. With a library system of building blocks,
							the user can quickly and accurately model complete electric and hybrid electric systems, analysing electric motor control and engine performance.
						</p>
					</Trans>

					{wave && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wave}
								alt="WAVE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_4">
						<p>
							WAVE is a 1D Computational Fluid Dynamics (CFD) tool that provides ICE data to the system model across a range of drive cycle scenarios.
						</p>
					</Trans>

					{mech && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={mech}
								alt="Structual Mechanics"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_5">
						<p>
							The structural mechanics suite, including SABR, ENGDYN and VALDYN,
							predict transmission and mechanical ICE losses as inputs to the drive cycle system model.
						</p>
					</Trans>

					{vectis && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vectis}
								alt="VECTIS"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_6">
						<p>
							Separately, VECTIS, is a 3D CFD simulation tool that predicts battery thermal behaviour
							enabling a detailed model of battery performance.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Solutions")}
						className="widget__sibling__pages">
						<AssetList>
							{MOTORCYCLE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{MARKETS_CAPABILITIES_ELECTRIC_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{HYBRID_ELECTRIFICATION_PRODUCTS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default MarketMotorcyclePage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-motorcycle-electrification", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/motorcycle/Realis-Simulation_1325_motorcycleHybrid.jpg" }) {
			...imageBreaker
		}
		capability: file(relativePath: { eq: "content/markets/motorcycle/Realis-Simulation_motorcycleHybrid.jpg" }) {
			...imageStandard
		}
		ignite: file(relativePath: { eq: "content/logos/IGNITE.png" }) {
			...imageBreaker
		}
		wave: file(relativePath: { eq: "content/products/wave/rs_wave.png" }) {
			...imageBreaker
		}
		mech: file(relativePath: { eq: "content/logos/StructuralMechanics_notxt.png" }) {
			...imageBreaker
		}
		vectis: file(relativePath: { eq: "content/products/vectis/rs_vectis.png" }) {
			...imageBreaker
		}
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { markets: { in: "Motorcycle" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 6
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;
